.instructionPanel {
    text-align: center;
}

.instructionPanel > h1 {
    text-align: center;
    padding-bottom: 3vh;
}

.instructionPanel > div {
    font-size: 50px;
    margin: auto;
    width: calc(60px + 3vw);
    height: calc(60px + 3vw);
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #8fd9cb;
    margin-top: 4vh;
}

.instructionPanel > div > h2 {
    font-size: calc(25px + 2.5vw);
    margin: auto;
}

.instructionPanel > p {
    text-align: left;
    padding: 0vh;
    padding-inline: 3vh;
    margin-bottom: 5vh;
}

.welcomePage {
    color: #f1efe5;
    background-color: #171409;
    margin: 0vw;
    height: calc(var(--vh) * 90);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    margin-inline: 5%;
    position: relative;
    overflow: hidden;
}

.welcomePage > * {
    margin: 5vw;
    z-index: +2;
}

.welcomePage > h1 {
    animation-iteration-count: 5;
    font-size: calc(25px + 2.5vw);
    text-shadow: 0px 0px 15px #171409;
}

.welcomePage > p {
    color: #f29025;
    font-size: calc(20px + 2.5vw);
    text-align: center;
    text-shadow: 0px 0px 15px #171409;
}

.welcome-img-div {
    position: absolute;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 0;
}

.welcome-img-div > img {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    position: absolute;
    object-fit: cover;
    justify-content: center;
    z-index: 0;
    opacity: 100%; 
    animation: fadeInWelcomeImg 5s;
    animation-iteration-count: 1;
}


@keyframes fadeInWelcomeImg {
    0% { 
        opacity: 0%;
    }
    100% { 
        opacity: 100%; 
    }
}

.welcome-img-mask {
    position: absolute;
    z-index: +1;
    width: 100%;
    height: 100%;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, #171409 0%,rgba(0, 0, 0, 0) 50%, #171409 100%);

}