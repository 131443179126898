.map-info-container {
    display: flex;
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.breweries-map-block {
    width: 50%;
    height: 90%;
    float: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.breweries-info-block {
    width: 50%;
    height: 90%;

}

.breweries-info-tag {
    height: 20%;
}
.breweries-info-tag > h1 {
    text-align: left;
}

.breweries-info-content {
    height: 50%;
}

.breweries-info-content > img {
    width: 100%;
    height: calc(var(--vh) * 20);
    object-fit: cover;
    justify-content: center;
}

.breweries-info-content > div {
    padding: 1vw;
}

.map-brewery {
    cursor: pointer;
    height: 10%;
    width: 50%;
    min-width: 60px;
    border: solid 2px #f29025;
    margin: 1vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0.5vw;
}

.map-brewery:hover, .map-brewery-active {
    background-color: #f29025;
    color: #f1efe5;
}
