.hideBeerModal, .hideBeerModalBackground {
    display: none;
}

.displayBeerModalBackground {
    position: absolute;
    top: 0px;
    left: 0px;
    height: calc(var(--vh) * 100);
    width: 100vw;
    z-index: 19;
    background-color: #000000a8;
    animation: fadeIn 0.4s;
    animation-iteration-count: 1;
}

.displayBeerModal {
    position: absolute;
    top: 12vh;
    left: 5vw;
    height: 78%;
    width: 88vw;
    z-index: 20;
    background-color: #f1efe5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5vw;
    padding-inline: 1vw;
    animation: fadeInModal 0.3s;
    animation-iteration-count: 1;
}


@keyframes fadeIn {
    0% { 
        opacity: 0%;
    }
    100% { 
        opacity: 100%; 
    }
}

@keyframes fadeInModal {
    0% { 
        opacity: 0%;
        top: 20vh;

    }
    90% {
        top: 10vh;
    }
    100% { 
        top: 12vh;
        opacity: 100%; 
    }
}

.closeBeerModal {
    background-color: #171409;
    color: #f1efe5;
    width: 50vw;
    min-width: 50px;
    max-width: 600px;
    height: 7vh;
    margin-top: 2vw;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10vh;
    cursor: pointer;
}

.closeBeerModal:hover {
    color: #f29025;
}

.beerModalImgDiv {
    height: calc(var(--vh) * 20);
    width: calc(var(--vh) * 20);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.beerModalImgDiv > img {
    background-color: #f1efe5;
    width: 90%;
    height: 90%;
    min-width: 60px;
    min-height: 60px;
    object-fit: contain;
    justify-content: center;
}

.beerModalInfoBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5vw;
}

.beerModalInfoBlock > h1, .beerModalInfoBlock > h3 {
    margin: 2px;
}

.beerModalUrlBalls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 2vw;
    max-width: 100%;
}
.beerModalUrlBalls > div, .beerModalUrlBalls > a {
    background-color: #f29025;
    height: min(7vh, 50px);
    min-width: 12vw;
    border-radius: 7vh;
    margin-inline: 1vh;
    padding-inline: 2vw;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-decoration: none;
    cursor: pointer;}

.beerModalUrlBalls > div:hover, .beerModalUrlBalls > a:hover {
    color:#171409;
}

@media screen and (min-width: 800px){
    .displayBeerModal {
        width: 700px;
        border-radius: 4vw;
        top: calc(50vh- 350px);
        left: calc(50vw - 350px);
        height: 700px;
    }
    
    .closeBeerModal {
        width: 200px;
        height: 7vh;
        margin-bottom: 10px;
        border-radius: 10vh;
        cursor: pointer;
    }

    .beerModalImgDiv {
        margin-top: 20px;
        height: calc(var(--vh) * 20);
        width: calc(var(--vh) * 20);
    }

    .beerModalImgDiv > img {
        width: 90%;
        height: 90%;
        min-width: 60px;
        min-height: 60px;
        object-fit: contain;
        justify-content: center;
    }

    .beerModalInfoBlock {
        margin: 10px;
    }

    .beerModalUrlBalls {
        margin: 20px;
    }
    .beerModalUrlBalls > div, .beerModalUrlBalls > a {
        height: 70px;
        width: 100px;
        padding: 0;
        border-radius: 70px;
        margin-inline: 10px;
    }
}