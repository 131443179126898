.map-block {
    height: calc(var(--vh) * 87 );
    min-height: calc(var(--vh) * 87 );
    margin-bottom: calc(var(--vh) * 3);
    width: 100%;
    position: relative;
}

.mapContainer {
    background-color: white;
    position: relative;
    width: 98%;
    min-width: 300px;
    height: calc(var(--vh) * 80 );
    max-height: 700px;
    min-height: 500px;
    margin: auto;
}

.mapContainer > div {
    position: absolute;
}

/*
BACKGROUND STUFF
*/
.map-background {
    background-color: #f1efe5;
    position: relative;
}

.bg1 {
    width: 30%;
    height: 60%;
    left: 15%;
    top: 10%;
}

.bg2 {
    width: 30%;
    height: 50%;
    left: 45%;
    bottom: 10%;
}


/*
STATIC STUFF
*/

.map-static {
    background-color: #00000010;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    font-size: 1vh;
}


.exit {
    bottom: 30%;
    left: 15%;
    width: 10%;
}

.gettingIn {
    width: 30%;
    left: 45%;
    bottom: 10%;
}

.wc {
    left: 15%;
    height: 6%;
    top: 10%;
    width: 15%;
    background-color: rgba(255, 127, 80, 0.355);
}

.smoking {
    background-color: rgba(208, 186, 43, 0.519);
    left: 30%;
    top: 10%;
    width: 15%;
    height: 6%;
}

.smoking > img {
    height: 60%;
}

.map-area {
    background-color: rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
}

.map-area > h3 {
    padding: 1%;
    margin: auto;
    align-items: center;
    font-size: 10px;
}


.seating {
    height: 40%;
    width: 20%;
    top: 20%;
    left: 18%;
}


/*
BREWERY STUFF
*/

.map-infoarea {
    color: #171409;
    height: 30%;
    width: 45%;
    left: 50%;
    top: 5%;
    position: relative;
    padding: 10px
}