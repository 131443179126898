#container-foam {
  position: absolute;
  width: 100%;
  bottom: -20%;
  z-index: +1;
}

#container-liquid {
  height: 100%;
  width: 100%;
  z-index: -1;
  position: relative;
}

#liquid {
  height: 100%;
  width: 100%;
}


@keyframes bubble {
  0% { 
    bottom: 0; 
    background-color: #f1efe57c;
    border: solid 2px #f1efe59a;
    transform: translateX(0);
  }

   50% {
      background-color: #f1efe53c;
      border: solid 1px #f1efe554;
      transform: translateX(50%);
      bottom: 50%;
   }

   100% {
      background-color: #f1efe500;
      border: solid 0.5px #f1efe51a;
      bottom: 100%;
      transform: translateX(0);
   }
}

.bubble {
  position: absolute;
  border-radius: 50%;
  height: 2%;
  max-height: 20px;
  aspect-ratio: 1/1;
  background-color: #f1efe5;
  border: solid 2px #f1efe5;
  bottom: -5%;
}

.bubble1 {
  left: 2%;
  animation: bubble 4.2s linear;
  animation-iteration-count: infinite;
}

.bubble2 {
  left: 20%;
  animation: bubble 3.1s linear;
  animation-iteration-count: infinite;
  animation-delay: 1000ms;
}

.bubble3 {
  left: 35%;
  animation: bubble 3.5s linear;
  animation-iteration-count: infinite;
  animation-delay: 700ms;
}

.bubble4 {
  left: 50%;
  animation: bubble 3s linear;
  animation-iteration-count: infinite;
  animation-delay: 2000ms;
}

.bubble5 {
  left: 70%;
  animation: bubble 2.5s linear;
  animation-iteration-count: infinite;
  animation-delay: 350ms;
}

.bubble6 {
  left: 80%;
  animation: bubble 3.2s linear;
  animation-iteration-count: infinite;
  animation-delay: 1000ms;
}




/* Foam */

.beer-foam {
  position: relative;
  width: 100%;
  height: 50%;
  top: 0%;
  background-color: #f29025;
}

.foam-1, .foam-2, .foam-3, .foam-4, 
.foam-5, .foam-6, .foam-7, .foam-8, 
.foam-9, .foam-10, .foam-11, .foam-12,
.foam-13, .foam-14, .foam-15
 {
  float: left;
  position: absolute;
  z-index: 0;
  width: 15%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-color: #f1efe5;
}
.foam-1 {
  bottom: 8%;
  left: -5%;
  animation: foam 3s ease-in-out;
  animation-iteration-count: infinite;
  animation-delay: 250ms;
}
.foam-2 {
  bottom: 8%;
  left: 2%; 
  animation: foam 3s ease-in-out;
  animation-iteration-count: infinite;
  animation-delay: 1000ms;
}
.foam-3 {
  bottom: 8%;
  left: 7%; 
  animation: foam 3s ease-in-out;
  animation-iteration-count: infinite;
  animation-delay: 0ms;
}
.foam-4 {
  bottom: 8%;
  left: 15%; 
  animation: foam 2.7s ease-in-out;
  animation-iteration-count: infinite;
  animation-delay: 70ms;
}
.foam-5 {
  bottom: 8%;
  left: 20%; 
  animation: foam 3s ease-in-out;
  animation-iteration-count: infinite;
  animation-delay: 2000ms;
}
.foam-6 {
  bottom: 8%;
  left: 27%; 
  animation: foam 3s ease-in-out;
  animation-iteration-count: infinite;
  animation-delay: 800ms;
}
.foam-7 {
  bottom: 8%;
  left: 34%;
  animation: foam 3.5s ease-in-out;
  animation-iteration-count: infinite;
  animation-delay: 250ms;
}

.foam-8 {
  bottom: 8%;
  left: 42%;
  animation: foam 3s ease-in-out;
  animation-iteration-count: infinite;
  animation-delay: 1200ms;
}
.foam-9 {
  bottom: 8%;
  left: 47%; 
  animation: foam 3s ease-in-out;
  animation-iteration-count: infinite;
  animation-delay: 50ms;
}
.foam-10 {
  bottom: 8%;
  left: 55%; 
  animation: foam 3s ease-in-out;
  animation-iteration-count: infinite;
  animation-delay: 1050ms;
}
.foam-11 {
  bottom: 8%;
  left: 62%; 
  animation: foam 3s ease-in-out;
  animation-iteration-count: infinite;
  animation-delay: 0ms;
}
.foam-12 {
  bottom: 8%;
  left: 70%; 
  animation: foam 8s ease-in-out;
  animation-iteration-count: infinite;
  animation-delay: 700ms;
}
.foam-13 {
  bottom: 8%;
  left: 77%; 
  animation: foam 3s ease-in-out;
  animation-iteration-count: infinite;
  animation-delay: 1400ms;
}
.foam-14 {
  bottom: 8%;
  left: 86%;
  animation: foam 4s ease-in-out;
  animation-iteration-count: infinite;
  animation-delay: 10ms;
}

.foam-15 {
  bottom: 8%;
  left: 95%;
  animation: foam 2s ease-in-out;
  animation-iteration-count: infinite;
  animation-delay: 700ms;
}

@keyframes foam {
  0% { 
    bottom: 10%; 
    width: 15%;
  }

   50% {
      bottom: 5%;
      width: 16%;
   }

   100% {
      bottom: 10%;
      width: 15%;
   }
}