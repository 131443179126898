.raffleArea {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 0;
    text-align: center;
    min-height: 90vh;
}

.alert-no-beers {
    color: #f28f25;
    margin-bottom: 5vh;
    text-decoration: underline;
}

.raffleLister {
    margin-bottom: 0px;
    text-align: left;
}


.raffleLister  .displayBeerModal {
    color: #171409;
}

.raffleLister  .closeBeerModal > h1 {
   margin: auto;
}

@media screen and (min-width: 700px){
    .raffleLister  .beerModalUrlBalls > * {
        padding: 0px;
        max-width: 150px !important;
        margin-inline: 5px;
     }
 }

.raffleLister .beerRow:hover {
    background-color: rgba(255, 255, 255, 0.068);
}


.raffleArea h1 {
    margin-top: 3vh;
}

.raffleButton {
    min-height: 8vh;
    min-width: 200px;
    width: 100vw;
    max-width: 400px;
    margin: 0vh;
    border-radius: 8vh;
    background-color: #f29025;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(15px + 1vw);
    text-align: center;
    cursor: pointer;
    color: #f1efe5;
    -webkit-tap-highlight-color: transparent;
}

.raffleButton-bottom {
    background-color: #f29025;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(15px + 1vw);
    text-align: center;
    cursor: pointer;
    color: #f1efe5;

    min-height: 6vh;
    width: 30vw;
    min-width: 150px;
    max-width: 400px;
    margin: 1vh;
    margin-top: 3vh;
    border-radius: 6vh;
    margin-bottom: 1px;
    -webkit-tap-highlight-color: transparent;
}

.raffleButton-bottom:hover {
    color: black;
}

.raffleButton-bottom:disabled {
    background-color: #525049;
    color:#ceccc4;
}

.raffleArea> div > h3 {
    margin: 1vh;
}

.raffleArea > div > h3 > span{
    color: #f29025;
    cursor: pointer;
    text-decoration: underline;
}

.raffleArea > h3 > span:hover{
    color: #d17a1d;
}


.styleQuery {
    margin-top: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.styleQuery > h2 {
    padding-inline: 1vw;
    margin: 1vh;
    margin-top: 2vh;
}

.styleQueryList {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding-inline: 1vw;
    margin: 1vh;
    
}
.styleBox {
    min-height: 3vh;
    min-width: 10vw;
    margin: 0.7vh;
    border-radius: 4vh;
    padding: 0.8vw;
    padding-inline: 2vw;
    background-color: #f28f25;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(12px + 0.8vw);
    text-align: center;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.inactive-style {
    background-color: #f28f2500;
    color: #898780;
    text-decoration: line-through;
}

@media screen and (max-width: 500px){

    .raffleArea h1 {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .raffleButton-bottom {
        margin-bottom: 20px;
    }


    
}

@media screen and (min-width: 800px){


.styleQueryList {
    margin: 30px;
    
}
.styleBox {
    min-height: 25px;
    min-width: 80px;
    margin: 4px;
    border-radius: 25px;
    padding: 2px;
    font-size: calc(17px);
}

}


.disabledButton {
    background-color: #898780;
    color: #171409;
}