.selectDisplayMode {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 20vh;
}

.selectDisplayMode > div {
    width: 40%;
    height: min(100px, 8vh);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: calc(15px + 1.5vw);
    cursor: pointer;
    border-radius: min(100px, 10vh);;
    -webkit-tap-highlight-color: transparent;
    border: #f29025 solid 2px;
    transition: all 0.4s;
}

.selectDisplayMode .activeMode {
    background-color: #f29025;
    color: white;
    transition: all 0.4s;
}

.centeredBeerTitle{
    text-align: center;
    padding: 2vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.centeredBeerTitle > a {
    height: 40px !important;
    width: 50vw !important;
    border-radius: 50%;
    background-color: #f29025;
    color: #171409;
}

.raffleBeerButton {
    min-height: 7vh;
    min-width: 200px;
    width: 50vw;
    max-width: 350px;
    border-radius: 8vh;
    background-color: #f29025;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(15px + 1.5vw);
    cursor: pointer;
    color: white;
    padding: 1vw;
    padding-inline: min(2vw, 20px)
}

.raffleBeerButton:hover {
    color: #171409;
}
.raffleBeerButton:disabled {
    background-color: #525049;
    color:#ceccc4;
}

.raffledBeer .beerRow {
    background-color: #52504900 !important;
}



@media screen and (min-width: 1000px){

    .raffleBeerButton {
        min-height: 60px;
        border-radius: 50px;
    }
}
  
.beerRow {
    min-height: calc(var(--vh) * 10);
    width: 100%;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    animation: displayRow 0.5s linear;
    animation-iteration-count: 1;
    background-color: #f1efe5;
}

.beerFlagged {
    background-color: #f29025;
    color: white;
}
.beerFlagged  * {
    color: white;
}

@keyframes displayRow {
    0% { 
        opacity: 0%;
    }
     100% {
        opacity: 100%;
     }
}


.beerRowTasted {
    filter: opacity(20%);
    animation: makeBeerTasted 2s ease-in-out;
    animation-iteration-count: 1;
}

@keyframes makeBeerTasted {
    0% { 
        filter: opacity(100%);
    }
     100% {
        filter: opacity(20%);
     }
}



.beerNotAvailable {
    background-color: #ceccc4;
    color: grey !important;
    filter: grayscale(100%) brightness(80%);
}

.brewery-img-container{
    min-height: 25vw;
    height: 100%;
    width: 25vw;
    aspect-ratio: 1/1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    position: relative;
}

.brewery-img-container > img {
    height: 70%;
    width: 70%;
    object-fit: contain;
    justify-content: center;
}

.beerModalImgDivAnimate > img {
    animation: displayRowBeer 0.4s ease-in-out;
    animation-iteration-count: 1;
}

@keyframes displayRowBeer {
    0% { 
        opacity: 0%;
        height: 50%;
    }
    90% {
        height: 75%;
    }
     100% {
        opacity: 100%;
        height: 70%;
     }
}

.random-beer-img {
    animation: raffleBeer 1s infinite linear;
}

@keyframes raffleBeer {
    0% {
      filter: blur(0px) opacity(0%);
    }
    25%{
        filter: opacity(50%);
    }
    50%{
        filter: opacity(20%) blur(1vw);
        
    }
    75%{
        filter: opacity(50%);
    }
    100% {
      filter: blur(0px) opacity(0%);
    }
  }

.beerInfoPart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 75vw;

}

@media screen and (min-width: 800px){
    .beerRow {
        min-height: 150px !important;
    }
    .brewery-img-container{
        min-height: 0px !important;
        max-height: 140px !important;
        max-width: 140px !important;
    }
    .beerChekboxContainer {
        min-height: 0px !important;
        height: 140px !important;
        margin: auto;
    }
    .beerCheckCircle {
        width: 140px !important;
        height: 140px !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .beerChekboxContainer {
        min-height: 0 !important;
        min-width: none !important;
        aspect-ratio: 1/1;
    }
    
    .beerCheckCircle {
        width: 100px !important;
        height: 100px !important;
        aspect-ratio: 1/1;
    }
}

.activeVersion {
    width: 50vw !important;
}

.beerInfoPart > * {
    padding: 0px;
    margin: 0px;
}

.beerName {
    font-weight: 1000;
    font-size: calc(15px + 1vw);
}
.additional-info-list {
    font-weight: 100;
    font-style: italic;
}

.additional-info-list {
    font-weight: 100;
    font-style: italic;
    font-size: smaller;
    color: #525049;
}

.beerModalInfoBlock img {
    height: 4vh;
    width: 4vh;
    object-fit: contain;
    justify-content: center;
}

.flaggedBeerIcon {
    position: absolute;
    top: 0;
    right: 0;
    height: 40% !important;
    width: 40% !important;
}


.beerBrewery {
    font-weight: 100;
    font-size: calc(14px + 0.8vw);
    color: #f29025;
}

.beerStyleABV {
    font-weight: 100;
    font-size: calc(15px + 0.5vw);
    color: #171409;
}

.beerChekboxContainer {
    height: 25vw;
    aspect-ratio: 1/1 ;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.beerCheckCircle {
    width: 70%;
    height: 70%;
    aspect-ratio: 1/1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #171409;
    position: relative;
    overflow: hidden;
}

.beerCheckCircle > img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    justify-content: center;
    z-index: +1;
}

.beerLevel {
    background-color: #f29025;
    position: absolute;
    bottom: 0;
    left: 5%;
    height: 70%;
    width: 90%;
    animation: fillbeer 1s ease-in-out;
    animation-iteration-count: 1;
}

@keyframes fillbeer {
    0%{height: 30%;} 
    40% {height: 75%;} 
    100% {height: 70%;} 
}





.pourBeerAway {
    animation: tiltGlass 3s linear;
    animation-iteration-count: 1;
}

@keyframes tiltGlass {
    0%, 100% {
        transform: rotate(0deg);
    } 
    10% {
        transform: rotate(-60deg);
    } 
    90% {
        transform: rotate(-130deg);
    } 
    100% {
        transform: rotate(0deg)
    }
}

.pourBeerAway > div {
    animation: tiltGlassInside 3s linear;
    animation-iteration-count: 1;
}

@keyframes tiltGlassInside {
    0% {
        height: 70%;
        transform: rotate(0deg);
        left: 0;
    } 
    10% {
        transform: rotate(60deg);
        left: 0;
        height: 70%;
        opacity: 100%;
    }
    50% {
        opacity: 50%;
    }
    90% {
        transform: rotate(100deg);
        left: -100px;
        height: 60%;
        opacity: 0%;
    }
    95% {
        height: 60%;
        transform: rotate(130deg);
        left: -40px;
        opacity: 0%;
    } 
    100%{
        height: 0%;
    }
}


.beerLevelTasted {
    height: 30%;
    animation: emptybeer 1s ease-in-out;
    animation-iteration-count: 1;
}

@keyframes emptybeer {
    0%{height: 70%;} 
    40% {height: 75%;} 
    100% {height: 30%;} 
}

.beerCheckCircle:hover {
    color: #171409;
}

@media screen and (min-width: 800px){

    .beerName {
        font-size: 25px;
    }
    
    .beerBrewery {
        font-size: 22px;
    }
    
    .beerStyleABV {
        font-size: 20px;
    }
}

.beerList {
    margin-bottom: calc(var(--vh) * 4);
    width: 100%;
}

.margin-10-percent {
    margin-top: 5%;
    margin-bottom: 5%;
}

.searchInput {
    width: 80vw;
    height: min(7vh, 60px);
    border-radius: 8vh; 
    padding-left: 20px;
    max-width: 500px;
    border: 1px solid #f29025;
    font-size: calc(12px + 1vw);
}

.searchInputContainer {
    text-align: center;
}

.searchInput::first-letter {
    padding-left:20px;
}

.boldLabel {
    font-weight: bold;
}

.beerONautWarning {
    padding-inline: 10%;
    font-style: italic;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation: fadeWarningIn 0.5s ease-in-out;
    animation-iteration-count: 1;
}


@keyframes fadeWarningIn {
0% { 
    opacity: 0;
}
 100% {
    opacity: 100%;
 }
}


.beerONautWarning > .warnPart {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 20vh !important;
    color:#ffffff;
    background-color: #f29025;
}

.warnPart > .warn {
    font-size: calc(80px + 5vw);
    margin-right: 4vw;
}
.warnMessage {
    width: 60%;
    font-size: calc(15px + 1vw);
}

.noBeersMatching {
    min-height: 40vh;
}

.noBeersMatching > h4 {
    text-align: center;
}
