.headBlock {
    width: 100%;
    min-height: min(300px, 30vh);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.headBlock > div {
    text-align: center;
    width: 90%;
}


.scheduleMain * {
    margin-inline: 1vw;
}

.scheduleMain div {
    font-size: min(20px, calc(12px + 1vw));
}

.scheduleMain h3 {
    font-size: min(25px, calc(20px + 2vw));
}



.scheduleDescription {
    font-style:italic;
    font-weight: 100;
    text-align:left;
    width: 90%;
    margin-inline: auto;
    text-align: center;
}
.scheduleTime {
    text-align: center;
    margin: auto;
    font-size: min(calc(14px + 2vw), 18px) !important;
    color: #f29025;
    font-style:italic;
    margin-top: 1.5vh;
    margin-bottom: 1.5vh;
}

.scheduleHeading {
    font-weight: bold;
    display: flex;
    font-size: min(calc(16px + 2vw), 25px) !important;
    text-align: left;
    text-align: center;
    margin: auto;
}

.scheduleItem {
    text-align: center;
    margin-bottom: 5vh;
    margin-top: 2vh;
}

.scheduleBlockHeading {
    margin-top: 3vh;
    font-weight: bold;
}
.flexSchedule {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.schedulePrice {
    font-weight: bold;
    margin-top: 2vh;
    font-size: min(calc(20px + 2vw), 30px) !important;
}

.middle-line {
    width: 50%;
    border-bottom: #f29025 dotted 10px;
    margin-bottom: 4vh;
}