@import url('https://fonts.googleapis.com/css?family=Amaranth&display=swap');
@import url('https://fonts.googleapis.com/css?family=Quicksand&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: -webkit-fill-available;
}

html{
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3 {
  font-family: 'Amaranth', sans-serif;
  font-weight: 700;
  font-style: bold;
  line-height: 1.15;
  letter-spacing: -.02em;
  margin: 0px;
}

h1 {
  margin: 0px;
  font-size: calc(25px + 1.6vw);
  text-align: center;
}

h4, h5, h6 {
  font-family: 'Amaranth', sans-serif;
  font-weight: 500;
  font-style: italic;
}

p {
  font-size: calc(12px + 0.9vw);
}

* {
  font-family: 'Amaranth', sans-serif;
  font-weight: 100;
  font-style: normal;
}

@media screen and (min-width: 800px){

  h1 {
    font-size: 40px;
  }
  
  p {
    font-size: 22px;
  }
}


.todo {
  background-color: rgb(238, 61, 61);
}

.external-url {
  color: #f29025 !important;
}
