.App-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(20px + 2vmin);
    background-color: #171409;
    color: #f1efe5;
    position: relative;
    padding-bottom: 0px;
    min-height: calc(var(--vh) * 100);
    text-align: center;
    padding-inline: 10vw;
}
  
.App-footer > img {
  position: absolute;
  bottom: 5%;
  width: 50vw;
  max-width: 400px;
}

.App-footer a {
  margin-top: 2vh;
  font-size: calc(35px + 2vw);
  text-decoration: none !important;
}

.App-footer a:hover {
  color: rgba(226, 131, 30, 0.705) !important;
}