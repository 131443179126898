.selectWeekends {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    -webkit-tap-highlight-color: transparent;
}

.selectWeekends > div, .displayActiveWeekend {
    height: min(5vh, 100px);
    font-size: calc(15px + 1vw);
    padding: min(1.5vh, 10px);
    margin-inline: 0;
    width: 50%;
    cursor: pointer;
    transition: all 0.3s;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
}

.activeWeekend {
    color: #f29025;
}

.selectWeekends input {
    width: 20%;
    color: #f29025;
    font: inherit;
    height: 1.15em;
    border-radius: 0% !important;
    appearance: none;
    -webkit-appearance: none;
}

.selectWeekends input[type='radio']:after {
    width: calc(15px + 1vw);
    height: calc(15px + 1vw);
    border-radius: calc(15px + 1vw);
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #d1d3d1;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
    transition: all 0.3s;
}

.selectWeekends input[type='radio']:checked:after {
    position: relative;
    background-color: #f29025;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
    transition: all 0.3s;
}