.userModule {
    padding: calc(var(--vh) * 5);
    padding-top: calc(var(--vh) * 5);
}

.openLogin > h1 {
    font-size: calc(12px + 2vw);
}

.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.makeUser  {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.makeUser > div  {
   min-height: 8vh;
   width: 40vw;
   margin: 4vh;
   border-radius: 8vh;
   background-color: #f29025;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   font-size: calc(15px + 1.5vw);
}

.loginAs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 4vh;
}

.loginAs > input {
    min-height: 8vh;
    width: 40vw;
    max-width: 200px;
    margin-inline: 2vw;
    border-radius: 8vh;
    text-align: center;
    font-size: calc(15px + 1.5vw);
}

.loginAs > button {
    min-height: 8vh;
    width: 30vw;
    max-width: 300px;
    border-radius: 8vh;
    margin-inline: 2vw;
    min-height: 8vh;
    border-radius: 8vh;
    background-color: #f29025;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(12px + 1.5vw);
    border: none;
}

.displayLoginInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-bottom: 5vh;
}


.displayLoginInfo > .displayLoginInfo-text {
    margin-inline: 2vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.displayLoginInfo > .displayLoginInfo-text > p >h2 {
    text-decoration: underline;
}

.displayLoginInfo-logout > button {
    min-height: 8vh;
    width: 50vw;
    max-width: 300px;
    border-radius: 8vh;
    margin-inline: 2vw;
    min-height: 8vh;
    border-radius: 8vh;
    background-color: #f29025;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(15px + 1.5vw);
    border: none;
}


.consentButtons {
    height: 10vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.consentButtons > div {
    margin-top: 5vh;
    margin-bottom: 3vh
}


.cancelButton {
    background-color: rgb(70, 70, 70) !important;
}