.App {
  text-align: left;
  overflow: scroll;
  height: calc(var(--vh) * 100);
  max-height: 100%;
  width: 100vw;
  overflow-x: hidden;
  background-color: #f1efe5;
}

.viewBlock {
  min-height: calc(var(--vh) * 90 - 3vh);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  max-width: 700px;
  margin: auto;
  margin-top: calc(var(--vh) * 3);
  margin-inline: 5%;
}

.viewBlockNoWH {
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  max-width: 700px;
  margin: auto;
}

.centerViewBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.minWidthBlock {
  align-items: center;
  justify-content: space-around;
  width: 100%;
  max-width: 700px;
  margin: auto;
  margin-top: 3vh;
  margin-bottom: 0;
  margin-inline: 2%;
}

@media screen and (max-width: 800px){

  .viewBlock {
    max-width: 600px;
  }
  
  .minWidthBlock {
    width: 600px;
    min-width: 95%;
    margin: auto;
    margin-top: calc(var(--vh) * 3);
    margin-bottom: 0;
    margin-inline: 2%;
  }
  
}

.centerText * {
  text-align: center;
  padding-inline: 1vw;
}

.orange-link {
  text-decoration: underline;
  color: #f28f25;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.fullVhBlock {
  min-height: calc(var(--vh) * 100);;

}

.noWH {
  height: fit-content !important;
  min-height: none !important;
}