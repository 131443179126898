.App-mini-header {
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f29025;
    color: #f1efe5;
    height: 10vh;
    z-index: 10;
    width: 100%;
    position: sticky;
    -webkit-tap-highlight-color: transparent;
}

.generalheader-logo {
    max-width: 50%;
    max-height: 60%;
    margin: auto;
}

.header-darkMode {
    background-color: #171409;
}

.naviToggler {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    aspect-ratio: 1/1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    font-size: 40px;
    cursor: pointer;
}

.navi-closed {
    transform: rotate(-90deg);
    animation: rotateNaviIconOpen 0.4s ;
    animation-iteration-count: 1;

}

.navi-open {
    transform: rotate(0deg);
    animation: rotateNaviIconClosed 0.4s ;
    animation-iteration-count: 1;
}

@keyframes rotateNaviIconOpen {
    0% { 
        transform: rotate(0deg);
    }
    100% { 
        transform: rotate(-90deg);
    }
}

@keyframes rotateNaviIconClosed {
    0% { 
        transform: rotate(-90deg);
    }
    100% { 
        transform: rotate(0deg);
    }
}

.lanIndicator {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    aspect-ratio: 1/1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    font-size: calc(18px + 1.5vw);
    text-transform: uppercase;
    cursor: pointer;
}

.hideNavigation {
    display: none;
}


.showNavigation {
    position: absolute;
    overflow: hidden;
    height: calc(var(--vh) * 90);
    width: 100%;
    top: 10vh;
    left: 0;
    z-index: 11;
    padding: 0px;
    margin: 0px;
    background-color: #f29025;
    animation: dropDown 0.4s ;
    animation-iteration-count: 1;
}

@keyframes dropDown {
    0% { 
        height: 0%;
    }
    50% { 
    }
    100% { 
        height: 90%;
    }
}


.App-navi-general {
    width: 100%;
    height: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden;
    position: relative;
    -webkit-tap-highlight-color: transparent;
}

.app-navi-general-content {
    width: 100%;
    height: 95%;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    margin-top: 1.5vh;
    position: absolute;
}

.App-navi-lan > span, .app-navi-general-content  > a {
    width: 100%;
    height: 10%;
    margin: 1vh;
    cursor: pointer;
    color: #f1efe5;
    text-decoration: none;
    font-size: calc(20px + 2vw);
    width: 100%;
    z-index: +2;
}


.app-navi-general-content  > a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    text-align: center;
}

@media screen and (min-width: 1000px){
    .App-navi-lan > span >span {
        font-size: 40px;
    }
    .App-navi-url > a, .App-navi-url > span {
        font-size: 40px;
    } 
}

.app-navi-general-content  > a:hover {
    text-decoration: underline;
}
.App-navi-lan > span {
    color: #f1efe56b;
}

.activeLanguage {
    color: #f1efe5 !important;
    text-decoration: underline;
}

.App-navi-user {
    height: 25%;
    background-color: #f1efe5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
}

.App-navi-user > h3 {
    color: #f29025;
    font-size: calc(20px + 2vw);
    z-index: +1;
    animation: fadeIn 0.4s ;
    animation-iteration-count: 1;
}

.App-navi-user > * {
    margin: 2vh;
    animation: fadeIn 0.4s ;
    animation-iteration-count: 1;
}

.navi-user-button {
    cursor: pointer;
    height: 7vh;
    padding-top: 1vh;
    padding-bottom: 1vh;
    max-height: 40px;
    width: 35vw;
    padding-inline: 3px;
    max-width: 250px;
    border-radius: 7vh;
    background-color: #f29025;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(14px + 1vw);
    border: none;
    cursor: pointer;
    color: #f1efe5;
    margin-inline: 2vw;
    z-index: +1;
    -webkit-tap-highlight-color: transparent;
    transition: background-color 500ms linear;
}

.highlightButton {
    background-color: #171409;
    transition: background-color 500ms linear;
}


.navi-user-logout:hover {
    text-decoration: underline;
    color: #f1efe5;
    cursor: pointer;
    border-radius: 30px;
}

@media screen and (min-width: 1000px){
    .App-navi-user > h3 {
        font-size: 40px;
    }

    .navi-user-button {
        font-size: 20px;
        max-height: 35px;
    }
}

.navi-login-part {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    max-width: 800px;
    max-height: 500px;
    z-index: +1;
    flex-direction: column;
}

.flexRowLogin {
    flex-direction: row;
    display: flex;

}

.consentBG {
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    height: calc(var(--vh) * 100);
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    color: white;
}

.flexRowConsent {
    max-width: 400px;
    padding: 5vw;
    border-radius: 5vw;
    width: 80%;
    background-color: #171409;

}

.consentText::-webkit-scrollbar:vertical,
.consentText::-webkit-scrollbar:window-inactive,
.consentText::-webkit-scrollbar-track,
.consentText::-webkit-scrollbar-thumb {
    width: 12px!important;
    color:#171409;
}

.consentText {
    height: 100%;
    overflow: scroll;
    text-align:justify;
    margin: 0px;
    padding: 10px;
    overflow-x: hidden;
}
.padding-10-consent {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    padding: 10px;
    height: 35vh;
    max-height: 300px;
    border: #f28f2584 solid 1px;
    border-radius: 4vw;
}

.padding-10 {
    padding: 10px;
    height: 150px;
    background-color:antiquewhite;
}

.consentTextCheckbox {
    padding:3%;
    font-size: 20px;
}

.navi-create-user {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.loginInput {
    height: 8vh;
    max-height: 35px;
    max-width: 500px;
    margin-inline: 2vw;
    border-radius: 8vh;
    text-align: center;
    font-size: calc(15px + 1.5vw);
    border: 0.25vh solid #f29025;
    text-transform: uppercase;
    height: calc(7vh - 0.5vh);
    padding-top: 1vh;
    padding-bottom: 1vh;
    max-height: calc(40px - 0.5vh);
        padding-inline: 3px;
    max-width: 250px;
    border-radius: 7vh;
    margin-bottom: 20px;
}

.loginInput::placeholder {
    text-transform: none;
    font-size: 20px;
    color:gray;
}

.navi-user-button:disabled {
    color:#cccec4;
    background-color: #525049;
}

.navi-create-user > input:focus {
    border: 0.5vh solid #f29025 !important;
}


.navi-black-underline {
    color: #171409;
    text-decoration: underline;
    margin-left: 2vw;
    cursor: pointer;
}


@media screen and (min-width: 1000px){
    .navi-login-part > * {
        font-size: 25px;
        padding: 15px;
    }

    .navi-create-user > input {
        font-size: 25px;
    }
    
    .navi-create-user > button {
        font-size: 25px;
    }
}
