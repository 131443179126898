.infomapContainer {
    background-color: azure;
    position: relative;
    padding-top: 0;
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}

.mapBlockItem {
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden !important;
    top: 0;
    left: 0;
    margin-bottom: 0;
    background-color: #171409;
}

.mapBlockItem > img {
    margin-bottom: 0;
    padding-bottom: 0;
}

.dimMap {
    filter: saturate(30%) brightness(50%);
    transition: all 0.5s;
}


.absolutePointers {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.absolutePointers > div {
    position: absolute;
}

.hide-infoscreen {
    display: none;
}

.sticky-infoscreen {
    position: fixed;
    top: calc(50vh - min(50vw, 400px)/2);
    right: 1vw;
    width: min(50vw, 400px);
    aspect-ratio: 1/1;
    border-radius: 50%;
    background-color: #f28f25;
    color: white;
    margin-top: 0;
    padding-top: 0;
    animation: litUpInfo 0.2s ease-out;
    animation-iteration-count: 1;
    z-index: +22;
    text-align: center;
    padding: 1vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: space-evenly;
}


@keyframes litUpInfo {
0% { 
    right: -70vw;
}
 100% {
    right: 0px;
 }
}

.sticky-infoscreenFood {
    position: fixed;
    top: calc(50vh - min(50vw, 400px)/2);
    left: 1vw;
    width: min(50vw, 400px);
    aspect-ratio: 1/1;
    border-radius: 50%;
    background-color: #f28f25;
    color: white;
    margin-top: 0;
    padding-top: 0;
    animation: litUpInfoFood 0.2s ease-out;
    animation-iteration-count: 1;
    z-index: +22;
    text-align: center;
    padding: 1vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}


@keyframes litUpInfoFood {
0% { 
    left: -60vw;
}
 100% {
    left: 0px;
 }
}

.sticky-content {
    height: 100%;
    max-height: 500px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sticky-content > img {
    height: 20vh;
    width: auto;
}

.closeSticky {
    position: absolute;
    top: 1px;
}


.selectMap {
    height: 30vh;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.selectWeekend {
    height: 20vh;
}

.selectMap > div {
    width: calc(40% - 4vw);
    min-height: min(100px, 8vh);
    padding: min(2vw, 10px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: min(calc(15px + 1.5vw), 30px);
    cursor: pointer;
    border-radius: min(100px, 10vh);;
    -webkit-tap-highlight-color: transparent;
    border: #f29025 solid 2px;
    transition: all 0.4s;
}

.SelButtonActive {
    background-color: #f28f25;
    color: white;
    transition: all 0.4s;
}

.breweryListBlock {
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    margin-bottom: 5vh;
}

.foodListBlock {
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    margin-bottom: 5vh;
}

.breweryListBlock h3, .foodListBlock h3 {
    background-color: #f28f25;
    margin-top: 2vh;
    color: white;
    border-radius: min(6vh, 32px);
    min-height: min(6vh, 32px);
    height: fit-content;
    padding: min(0.5vw, 3px);
    padding-inline: min(1.5vw, 8px);
    font-weight: 100;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    cursor: pointer;
}