.absolutePointers > div {
    height: 6.3%;
    width: 12.5%;
    align-items: center;
    justify-content: center;
    text-align: center;
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.absolutePointers > div > div {
    border-radius: 1vw;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.absolutePointers > div:hover > div {
    color: #f28f25;
    transition: all 0.2s;
}

.highlightElement {
    background-color: #f28f25;
    color: white !important;
    transition: all 0.2s;
}

.absolute-11 {
    top: 6.5%;
    left: 8%;
}


.absolute-10 {
    top: 13.5%;
    left: 8%;
}

.absolute-9 {
    top: 20.2%;
    left: 8%;
}

.absolute-8 {
    top: 27%;
    left: 8%;
}

.absolute-7 {
    top: 33.7%;
    left: 8%;
}

.absolute-6 {
    top: 40.5%;
    left: 8%;
}

.absolute-5 {
    top: 47%;
    left: 8%;
}

.absolute-4 {
    top: 54.5%;
    left: 7.0%;
    transform: rotate(5deg);
}

.absolute-3 {
    top: 61.2%;
    left: 6.2%;
    transform: rotate(5deg);
}

.absolute-2 {
    top: 67.6%;
    left: 5.5%;
    transform: rotate(5deg);
}

.absolute-1 {
    top: 74.3%;
    left: 4.6%;
    transform: rotate(5deg);
}


.absolute-R20 {
    top: 47.5%;
    left: 72.2%;
    height: 4.8% !important;
    width: 17% !important;
}

.absolute-R21 {
    top: 57.3%;
    left: 89.2%;
    height: 8.8% !important;
    width: 9% !important;
}

.absolute-R22 {
    top: 67.5%;
    left: 89.2%;
    height: 8.8% !important;
    width: 9% !important;
}